<template>
  <div class="vito-login-main-wrapper">
    <div class="vito-login-sub-div-wrapper">
      <div class="vito-n-logo">N</div>
      <div class="vito-form-wrapper">
        <div class="login-text-wrapper">
          <span class="login-text">Login</span>
        </div>
        <div>
          <a-form
            layout="inline"
            style="display: contents"
            :model="formState"
            @finish="handleFinish"
            @finishFailed="handleFinishFailed"
          >
            <a-form-item style="margin: 20px 0px">
              <a-input
                v-model:value="modelRef.email"
                placeholder="Email"
                style="height: 40px"
              >
                <template #prefix><UserOutlined /></template>
              </a-input>
            </a-form-item>
            <a-form-item style="margin: 20px 0px">
              <a-input
                v-model:value="modelRef.password"
                type="password"
                placeholder="Password"
                style="height: 40px"
              >
                <template #prefix><LockOutlined /></template>
              </a-input>
            </a-form-item>
            <div class="remember-me-forget-wrapper">
              <div>
                <a-checkbox
                  v-model:checked="checked"
                  class="rember-me-check-box"
                  >Remember me</a-checkbox
                >
              </div>
              <div class="forgot-your-password-text">Forgot your password?</div>
            </div>
            <a-form-item class="sign-in-btn-wrapper">
              <a-button type="primary" html-type="submit" @click="isSignIn">
                Sign In
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import Services from "../../../services/apis";
import { Form } from "ant-design-vue";

export default {
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const router = useRouter();
    const useForm = Form.useForm;
    let modelRef = reactive({
      email: "",
      password: "",
    });
    const id = ref();
    const { resetFields, validate, validateInfos } = useForm(
      modelRef
      // rulesRef
    );
    const token = localStorage.getItem("Token");
    const user = localStorage.getItem("User");
    console.log('user :>> ', user);
    const validation = () => {
      if (token) {
        router.push("/dashboard");
        message.success(`Welcome ${user}`);
      }
    };
    const isSignIn = () => {
      validate().then((result) => {
        Services.signIn(modelRef)
          .then((response) => {
            if (response.status === 200) {
              localStorage.setItem("Token", response.data.token);
              localStorage.setItem("User", response.data.name);
              localStorage.setItem("UserId", response.data.id);
              id.value = response.data.id;
              message.success(`Login Succesful`);
              router.push("/dashboard");
            }
          })
          .catch((err) => {
            message.error(`Invalid Credentials`);
          });
      });
    };
    const formState = reactive({
      user: "",
      password: "",
    });
    const handleFinish = (values) => {
      console.log(values, formState);
    };

    const handleFinishFailed = (errors) => {
      console.log(errors);
    };
    onMounted(() => {
      validation();
    });

    return {
      isSignIn,
      formState,
      handleFinish,
      handleFinishFailed,
      modelRef,
      checked: ref(true),
    };
  },
};
</script>
<style lang="scss">
@import "../styles/login.scss";
</style>
